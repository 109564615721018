<template>
  <div>
    <div :class="isMobile ? 'mobile-box' : 'pc-box'">
      <div class="login">
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E6%89%8B%E6%9C%BA.png"
            alt=""
          />
          <div class="label">手机号</div>
          <input
            style="width: 80%"
            class="input"
            type="text"
            placeholder="请输入手机号"
            v-model="phone"
          />
        </div>
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E9%94%81.png"
            alt=""
          />
          <div class="label">验证码</div>
          <input
            style="width: 40%"
            class="input"
            type="text"
            placeholder="请输入验证码"
            v-model="phoneCode"
          />
          <button class="btn-code" :disabled="isCountingDown" @click="getCode">
            {{ isCountingDown ? `${countdown}s后重试` : "获取验证码" }}
          </button>
        </div>
        <button class="btn-login" @click="login">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import axios from "axios";

export default {
  data() {
    return {
      phone: "",
      phoneCode: "",
      isCountingDown: false,
      countdown: 60,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    // validatePhone() {
    //   const phoneRegex = /^1[3-9]\d{9}$/;
    //   if (this.phone && !phoneRegex.test(this.phone)) {
    //     Toast.fail("请输入有效的手机号");
    //   }
    // },
    getCode() {
      if (!this.phone) {
        Toast.fail("请输入手机号");
        return;
      }
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.phone)) {
        Toast.fail("手机号格式不正确");
        return;
      }

      // 模拟发送验证码请求
      axios
        .post(
          // `http://192.168.1.217:8081/assess/getCode4LoginNoUser`,

          `https://assess.eapchina.net/getCode4LoginNoUser`,
          `phone=${encodeURIComponent(this.phone)}&&unitId=1824`
        )
        .then((res) => {
          if (res.data) {
            if (res.data.error == "用户手机号不存在！") {
              Toast.fail("用户手机号不存在！");
            } else {
              this.startCountdown();
            }
          } else {
            // Toast.fail(response.data.message || "发送验证码失败");
          }
        })
        .catch((error) => {
          Toast.fail(error.message || "发送验证码失败");
        });
    },
    startCountdown() {
      this.isCountingDown = true;
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.isCountingDown = false;
        }
      }, 1000);
    },
    login() {
      if (!this.phone || !this.phoneCode) {
        Toast.fail("请输入完整的手机号和验证码");
        return;
      }

      axios
        .post(
          // `http://192.168.1.217:8081/assess/userLogin4HC`,

          `https://assess.eapchina.net/userLogin4HC`,
          `phone=${encodeURIComponent(
            this.phone
          )}&phoneCode=${encodeURIComponent(this.phoneCode)}&&unitId=1824`
        )
        .then((response) => {
          console.log(response);
          if (response.data.userId) {
            Toast.success("登录成功");
            localStorage.setItem("userId", response.data.userId);
            setTimeout(() => {
              this.$router.push("/CP/huachuang/guide");
            }, 1000);
          } else {
            if (response.data.error) {
              Toast.fail(response.data.error);
            } else {
              Toast.fail("登录异常");
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            Toast.fail(
              `登录失败：${error.response.status} - ${
                error.response.data.message || "服务器错误"
              }`
            );
          } else if (error.request) {
            Toast.fail("网络错误");
          } else {
            Toast.fail("登录失败");
          }
        });
    },
  },
  created() {
    // localStorage.clear();
    localStorage.removeItem("userId");
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.mobile-box,
.pc-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-box {
  background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");
  background-size: cover;
  background-position: center;
}
.pc-box {
  background-color: rgb(176, 213, 255);
}
.login {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  max-width: 400px; /* 限制登录框最大宽度 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  height: 50px;
  padding: 0 10px;
}
.icon {
  width: 30px;
  height: 30px;
}
.label {
  font-size: 16px;
  color: #5c5857;
  width: 48px;
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  color: #949494;
  flex: 1;
}
.btn-login {
  background-color: rgb(74, 122, 194);
  color: #fff;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}
.btn-code {
  background-color: #4a7ac2;
  color: #fff;
  border: none;
  padding: 0 10px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

/* 移动端样式调整 */
@media (max-width: 768px) {
  .login {
    background-color: #fff; /* 恢复白色背景 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 保留阴影 */
    width: 90%; /* 宽度改为相对值 */
    max-width: none; /* 移除最大宽度限制 */
    border-radius: 10px;
  }
}
</style>
